import React from 'react';

// import StoreCourseSlider from '../Shop/CourseSlider';
// import ShopSectionComponent from '../Shop/Section';
import dynamic from 'next/dynamic';

import classNames from 'classnames';

import { EVENT_NAME, useMoengageEventTracker } from '../../hooks/useMoengage';

import { CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY } from '../../constants/api.common';

import styles from './CourseSlider.module.scss';

const StoreCourseSlider = dynamic(() => import('../Shop/CourseSlider'));
const ShopSectionComponent = dynamic(() => import('../Shop/Section'));

function CourseSlider({ title, subtitle, storageKey, ...props }) {
  const trackMoengageEvent = useMoengageEventTracker();

  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    const continuePurchaseProducts = JSON.parse(window.localStorage.getItem(storageKey) || '[]');

    if (continuePurchaseProducts.length) {
      setProducts(continuePurchaseProducts.reverse());
    }
  }, []);

  const onProductClick = (product) => {
    let eventKey = EVENT_NAME.RECENTLY_VIEWED_CLICKED;
    if (storageKey === CONTINUE_PURCHASE_PRODUCTS_STORAGE_KEY) {
      eventKey = EVENT_NAME.CONTINUE_PURCHASE_CLICKED;
    }

    trackMoengageEvent(eventKey, product);
  };

  if (products.length === 0) return null;

  return (
    <ShopSectionComponent title={title} subtitle={subtitle} className={classNames(styles.wrapper)} {...props}>
      <StoreCourseSlider products={products} onProductClick={onProductClick} showPrice />
    </ShopSectionComponent>
  );
}

export default CourseSlider;
